import AboutMe from "../components/AboutMe";
import ProjectShowcase from "../components/ProjectShowcase";
import GalleryView from "./GalleryView";
import AllPostsView from "./AllPostsView";

const LandingView = () => {
  return (
    <div className="landing-container">
      <AboutMe />
      <ProjectShowcase />
      <AllPostsView />
      <GalleryView />
    </div>
  )
};

export default LandingView;
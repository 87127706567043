import { Link } from "react-router-dom"
import { AlbumCardProps } from "../interfaces"

const AlbumCard = ({ album }: AlbumCardProps) => {
  const { title, cover: { url = '' }, meta: { id } } = album;
  return (
    <Link
      className="album-card"
      to={ `/gallery/${id}`}
    >
      <img
        src={`${url}?w=1000`}
        alt={title}
      />
      <div>
        {title}
      </div>
    </Link>
  )
}

export default AlbumCard;

// Context
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ContextConsumer from "../services/ContextConsumer";
import { PostEntry } from "../interfaces";
import { Link } from "react-router-dom";

const AllPostsView = () => {
  // Translations
  const { i18n : { language }, t } = useTranslation();

  // Context
  const { context: { getPosts } } = ContextConsumer();

  // State
  const [postsENUS, setPostsENUS] = useState<PostEntry[]>([]);
  const [postsESCL, setPostsESCL] = useState<PostEntry[]>([]);

  // Get Posts
  useEffect(() => {
    getPosts()
      .then(([es, us]) => {
        setPostsESCL(es);
        setPostsENUS(us);
      });
  }, []);

  return (
    <div className="container blog-container">
      <h1>
        {t('blog.title')}
      </h1>
      <div className="blog-entries">
        {(language === 'es_CL' ? postsESCL : postsENUS).map((post) =>(
            <Link
            to={`/blog/${post.slug}`}
            key={post.slug}
          >
            <img src={post.banner.url} alt={post.title} />
              {post.title}
          </Link>
        ))}
      </div>
    </div>
  )
};

export default AllPostsView;
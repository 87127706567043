import { ProjectInterface } from "../interfaces";

const projects: ProjectInterface[] = [
  {
    name: 'mercado libre',
    period: '2023',
    logo: '/images/mercadolibre.png',
    keywords: [
      ['B2B', 'E-Commerce'],
      ['Webapp'],
      ['Typescript', 'React', 'Sass', 'Graphql'],
    ],
    lightGradient: {
      from: '#ffee2f',
      to: '#e5cb34',
    }, darkGradient: {
      from: '#ffee2f',
      to: '#e5cb34',
    },
  },
  {
    name: 'bagoost',
    period: '2022',
    logo: '/images/bagoost.png',
    keywords: [
      ['B2B', 'E-Commerce'],
      ['Webapp'],
      ['Typescript', 'React', 'Sass', 'Graphql'],
    ],
    lightGradient: {
      from: '#333333',
      to: '#000000',
    }, darkGradient: {
      from: '#333333',
      to: '#000000',
    }
  },
  {
    period: '2022',
    name: 'dealing',
    logo: '/images/dealing.png',
    keywords: [
      ['B2B', 'E-Commerce'],
      ['IOS', 'Android'],
      ['Typescript', 'React Native'],
    ],
    lightGradient: {
      from: '#4479FB',
      to: '#28C3FF',
    }, darkGradient: {
      from: '#4479FB',
      to: '#28C3FF',
    }
  },
  {
    period: '2022',
    name: 'utfsm',
    logo: '/images/usm.svg',
    keywords: [
      ['Web'],
      ['Wordpress', 'PHP']
    ],
    lightGradient: {
      from: '#005E90',
      to: '#084D79',
    }, darkGradient: {
      from: '#005E90',
      to: '#084D79',
    }
  }
]

export default projects;
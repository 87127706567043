import { useTranslation } from "react-i18next";

const LanguageToogle = () => {
  const { i18n } = useTranslation();
  
  return (
    <div
      className="toogle-language"
      onClick={() => i18n.changeLanguage(i18n.language === 'es_CL' ? 'en_US' : 'es_CL')}
    >
      <li>
        {(i18n.language === 'es_CL') ?  'Español' : 'English'}
      </li>
    </div>
  );
};

export default LanguageToogle;

// Interfaces
import { ProjectInterface } from "../interfaces";
import ContextConsumer from "../services/ContextConsumer";

const ProjectCard = (props: ProjectInterface) => {
  const {
    logo,
    name,
    lightGradient,
    darkGradient
  } = props;

  const { state: { theme } } = ContextConsumer();

  const {from, to} = theme === 'dark' ? darkGradient : lightGradient; 

  return (
    <div
      className="project-card"
      style={{ background: `linear-gradient(315deg, ${from} 0%, ${to} 100%)` }}
    >
      <img
        src={logo}
        alt={`Gaspar Correa - ${name}`}
      />
    </div>
  )
};

export default ProjectCard;
import ContextConsumer from '../services/ContextConsumer';

// SVG
import { ReactComponent as Sun } from '../svg/Sun.svg';
import { ReactComponent as Moon } from '../svg/Moon.svg';

const ThemeToogle = () => {
  const { state: { theme }, context: { toogleTheme } } = ContextConsumer();

  return (
    <li className="theme-toogle" onClick={() => toogleTheme()}>
      {theme === 'dark' ? <Moon /> : <Sun />}
      {theme}
    </li>
  );
};

export default ThemeToogle;
const footer = () => {
  return (
    <footer>
      <a
        href="mailto:gasparcorreavergara@gmail.com"
        target="_blank"
        rel="noreferrer"
      >
        MAIL
      </a>
      <a
        target="_blank"
        href="https://www.linkedin.com/in/gaspar-correa-vergara-09b525153/"
        rel="noreferrer"
      >
        LINKEDIN
      </a>
    </footer>
  )
};

export default footer;
// Translation
import  { useTranslation } from 'react-i18next';

import ProjectCard from "../components/ProjectCard";

// Constant
import projects from '../constants/projects';

const ProjectShowcase = () => {
  // Translation
  const { t } = useTranslation();

  return (
    <section className="project-showcase">
      <div className="container">
        <h1 className="title">
          {t('projectsShowcase.title')}
        </h1>
      </div>
      <div className="container">
        {projects.map((project) => (
          <ProjectCard
            {...project}
            key={project.name}
          />
        ))}
      </div>
    </section>
  )
};

export default ProjectShowcase;
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';


// Services
import ContextConsumer from '../services/ContextConsumer';

// Interfaces
import { AlbumInterface } from '../interfaces';
import AlbumView from './AlbumView';
import Albums from '../components/Albums';

const GalleryView = () => {
  // Context
  const { context: { getAlbums } } = ContextConsumer();

  const { id } = useParams();

  // State
  const [albums, setAlbums] = useState<AlbumInterface[]>([]);

  useEffect(() => {
    getAlbums()
      .then((response) => setAlbums(response));
  }, []);

  if (id) return <AlbumView id={id} albums={albums} />;

  return <Albums albums={albums} />;
}

export default GalleryView;
import axios from 'axios';

const {
  REACT_APP_SPACE,
  REACT_APP_TOKEN,
} = process.env;

const api = async (data: string) => {
  const baseConfig = {
    method: 'POST' as 'POST',
    timeout: 5000,
    url: `https://graphql.contentful.com/content/v1/spaces/${REACT_APP_SPACE}`,
    headers : {
      'Authorization': `Bearer ${REACT_APP_TOKEN}`, 
      'Content-Type': 'application/json'
    },
  }
  const config = {...baseConfig, data};
  const result = axios(config)
    .then((response) => response.data.data)
    .catch((err) => {
      throw err
    });
  return result;
};

export default api;
import { useEffect, useState } from 'react';

// Components
import Album from '../components/Album';

// Services
import ContextConsumer from '../services/ContextConsumer';

// Interfaces
import { AlbumInterface, AlbumViewProps } from '../interfaces';
import Albums from '../components/Albums';

const AlbumView = ({ id, albums }: AlbumViewProps) => {
  // Context
  const { context: { getAlbum } } = ContextConsumer();


  // State
  const [album, setAlbum] = useState<AlbumInterface>();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    getAlbum(id)
      .then((response) => setAlbum(response));
  }, [id]);

  if (album) {
    return (
      <div className="gallery-view-container">
        <div className="container description">
          <h1>
            {album.title}
          </h1>
        </div>
        <Album album={album} />
        <Albums albums={albums} title='photoGallery.more' />
      </div>
    );
  }

  return <div />;

}

export default AlbumView;
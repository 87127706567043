import { useTranslation } from "react-i18next";
import { AlbumsProps } from "../interfaces";
import AlbumCard from "./AlbumCard";
import { useParams } from "react-router-dom";

const Albums = ({ albums, title = 'photoGallery.title' }: AlbumsProps) => {
  const { t } = useTranslation();

  const { id } = useParams();

  return (
    <div className="container gallery-view-container">
      <div className="description">
        <h1>
          {t(title)}
        </h1>
      </div>
      <div className="albums">
        {albums
          .filter(({ meta: { id: albumId } }) => albumId !== id)
          .map((album) => <AlbumCard key={album.meta.id} album={album} />
        )}
      </div>
    </div>

  );
}

export default Albums;

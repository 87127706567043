import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Components
import ThemeToogle from "./ThemeToogle";
import LanguageToogle from "./LanguageToogle";

const Navbar = () => {
  // Translation
  const { t } = useTranslation();

  return (
    <nav>
      <div className="container">
        <ul>
          <li>
            <Link
              to="/"
            >
              {t('navbar.home')}
            </Link>
          </li>
          <li>
            <Link to="/gallery">
              {t('navbar.gallery')}
            </Link>
          </li>
          <Link
            to="/blog"
          >
            {t('navbar.blog')}
          </Link>
        </ul>
        <ul className="config">
          <ThemeToogle />
          <LanguageToogle />
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
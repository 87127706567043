// Interfaces
import { AlbumProps } from "../interfaces";

const Album = (props: AlbumProps) => {
  const {
    album: {
      title,
      images: {
        items,
      }
    },
  } = props;

  return (
    <section className="container album">
      <div className="album-gallery">
        {items.map(({
          meta: {
            id,
          },
          url,
          width,
          height,
        }) => (
          <a
            key={id}
            href={url}
            target="_blank"
            rel="noreferrer"
            data-ratio={width > height ? 'landscape' : 'portrait'}
          >
            <img
              src={`${url}?w=1000`}
              alt={title}
            />
          </a>
        ))}
      </div>
    </section>
  );
};

export default Album;
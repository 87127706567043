import { ReducerActionInterface, StateInterface } from "../interfaces";

const theme = localStorage.getItem('theme') === 'light' ? 'light' : 'dark';
export const initialState : StateInterface = {
  theme,
  cv: false,
};

const reducer = (state: StateInterface, action: ReducerActionInterface): StateInterface => {
  switch (action.type) {
    case 'TOOGLE_THEME':
      const theme = state.theme === 'dark' ? 'light' : 'dark';
      localStorage.setItem('theme', theme);
      return {
        ...state,
        theme,
      };
    case 'TOOGLE_CV':
      return {
        ...state,
        cv: !state.cv,
      };
    default:
      return state;
  }
};

export default reducer;
// Translations
import { useTranslation } from 'react-i18next';

const AboutMe = () => {

  // Translations
  const { t } = useTranslation();

  return (
    <section className="about-me">
      <div className="container">
        <div className="information">
          <div className="title">
            <div>
              {t('aboutMe.titlePart1')}
            </div>
            <div>
              <span className="highlight">
                {t('aboutMe.titlePart2')}
              </span>
              <span>
                {t('aboutMe.titlePart3')}
              </span>
            </div>
          </div>
        </div>
        <div>
          <img
            className="avatar"
            src={'/images/me.png'}
            alt="Gaspar Octavio - Software Engineer"
          />
        </div>
      </div>
    </section>
  );
};

export default AboutMe;
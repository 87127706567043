import { Dispatch } from "react";

// API
import api from '../api';

// Interfaces
import { ContextInterface, PostEntry, PostInterface } from "../interfaces/Context.interface";
import { ReducerActionInterface } from "../interfaces/State.interface";

function getGlobalContext(dispatch: Dispatch<ReducerActionInterface>) : ContextInterface | undefined {
  const context = {
    toogleTheme: () => dispatch({ type: 'TOOGLE_THEME' }),
    getAlbums: () => {
      const data = JSON.stringify({
        query: `query {
          albumCollection {
            items {
              title
              cover {
                url
              }
              meta:sys {
                id
                publishedAt
              }
            }
          }
        }`,
      });
      const result = api(data).then((response) => response.albumCollection.items);
      return result;
    },
    getAlbum: (id : string) => {
      const data = JSON.stringify({
        query: `query {
          album(id: "${id}") {
            title
            images:imagesCollection(limit: 36) {
              items {
                meta:sys {
                  id
                  publishedAt
                }
                url
                height
                width
                size
                }
              }
              contentfulMetadata {
                tags {
                  id
                  name
                }
              }
              meta:sys {
                id
                publishedAt
              }
            }
        }`,
      });
      const result = api(data)
        .then((response) => response.album)
        .catch((err) => {
          throw err;
        });
      return result;
    },
    getPosts: () => {
      const data = JSON.stringify({
        query: `
        query {
          es_CL:postCollection(locale: "es-CL", order: sys_publishedAt_DESC) {
            items {
              title
              banner {
                url
              }
              slug
              sys {
                id
              }
            }
          }
          en_US:postCollection(locale: "en-US", order: sys_publishedAt_DESC) {
            items {
              title
              banner {
                url
              }
              slug
              sys {
                id
              }
            }
          }
        }`
      });
      const result = api(data)
        .then((response) => [
          response.es_CL.items as PostEntry[],
          response.en_US.items as PostEntry[],
        ] as [PostEntry[], PostEntry[]])
        .catch((err) => {
          throw err;
        });
      return result;
    },
    getPost: (slug: string) => {
      const data = JSON.stringify({
        query: `
        query {
          es_CL: postCollection(limit: 1, where: { slug : "${slug}"}, locale: "es-CL") {
            items {
              title
              slug
              content {
                json
                links {
                  assets {
                    block{
                      sys {
                        id
                      }
                      url
                    }
                  }
                }
              }
            }
          }
          en_US: postCollection(limit: 1, where: { slug : "${slug}"}, locale: "en-US") {
            items {
              title
              slug
              content {
                json
                links {
                  assets {
                    block{
                      sys {
                        id
                      }
                      url
                    }
                  }
                }
              }
            }
          }
        }
        `
      });
      const result = api(data)
        .then((response) => [
          response.es_CL.items[0] as PostInterface,
          response.en_US.items[0] as PostInterface
        ] as [PostInterface, PostInterface])
        .catch((err) => {
          throw err;
        });
      return result;
    },
  }
  return context;
}

export default getGlobalContext;
import { useContext } from "react";

// Interfaces
import { ContextInterface, StateInterface } from "../interfaces";

// Context
import GlobalContext from "../store/GlobalContext";

const ContextConsumer = () => {
  const { state, context } : { state: StateInterface , context: ContextInterface | undefined } = useContext(GlobalContext);
  if (context === undefined) {
    throw new Error('ContextConsumer must be inside a ContextProvider');
  }
  return {
    state,
    context
  }
}

export default ContextConsumer;
import { useReducer } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

// Sass
import './styles/index.scss';

// Global State
import reducer, { initialState } from './store/reducer';
import getGlobalContext from './store/getGlobalContext';
import GlobalContext from './store/GlobalContext';

// Views
import LandingView from './views/LandingView';
import AllPostsView from './views/AllPostsView';
import PostView from './views/PostView';

// Components
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import NotFoundView from './views/NotFoundView';
import GalleryView from './views/GalleryView';

function App() {
  // Global State
  const [state, dispatch] = useReducer(reducer, initialState);
  const context = getGlobalContext(dispatch);

  return (
    <Router>
      <GlobalContext.Provider value={{ state, context }}>
        <div className="app" data-theme={state.theme}>
          <Navbar />
          <Routes>
            <Route
              path="/"
              element={<LandingView />}
            />
            <Route
              path="/gallery"
              element={<GalleryView />}
            >
             <Route
              path=":id"
              element={<GalleryView />}
             />
            </Route>
            <Route
              path="/blog"
              element={<AllPostsView />}
            />
            <Route
              path="/blog/:slug"
              element={<PostView />}
            />
            <Route
              path="*"
              element={<NotFoundView />}
            />
          </Routes>
          <Footer />
        </div>
      </GlobalContext.Provider>
    </Router>
  );
}

export default App;

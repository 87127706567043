import { useEffect, useState, useMemo } from "react";
import Highlight from "react-highlight";
import "highlight.js/styles/monokai.css";

import { useTranslation } from "react-i18next";

// Interfaces
import { PostInterface, PostNode } from "../interfaces";

// Context
import ContextConsumer from "../services/ContextConsumer";
import { useParams } from "react-router-dom";

const PostView = () => {
  // Context
  const { context: { getPost } } = ContextConsumer();

  // Translations
  const { i18n : { language } } = useTranslation();

  // Slug param
  const { slug } = useParams();

  // State
  const [postENUS, setPostENUS] = useState<PostInterface>();
  const [postESCL, setPostESCL] = useState<PostInterface>();

  // Image reducer
  const images = useMemo(() => {
    if (!postESCL) return {};
    return postESCL.content.links.assets.block.reduce((acc, curr) => {
      acc[curr.sys.id] = curr.url;
      return acc;
    }, {} as { [key: string] :  string });
  }, [postESCL]);

  // Get post
  useEffect(() => {
    if (!slug) return;
    getPost(slug)
      .then(([es, us]) => {
        setPostENUS(us);
        setPostESCL(es);
      });
  }, [slug]);

  const CodeMapper = ({ node } : { node: PostNode }) : string => {
    if (node.nodeType === 'text') return (node.value);
    if (node.nodeType !== 'embedded-asset-block') {
      const childNodes = node.content.map((node) => CodeMapper({node: node}));
      return childNodes.join('\n');
    }
    return "";
  }
  
  const NodeWrapper = ({ node } : { node : PostNode }) => {
    const contentMapper = (childNodes : PostNode[]) => {
      return childNodes.map((childNode, index) => (
        <NodeWrapper
          node={childNode}
          key={`${childNode.nodeType} - ${index}`}
        />
      ));
    };
    if (node.nodeType === 'heading-1') return (
      <h1>
        {contentMapper(node.content)}
      </h1>
    );
    if (node.nodeType === 'heading-2') return (
      <h2>
        {contentMapper(node.content)}
      </h2>
    );
    if (node.nodeType === 'heading-3') return (
      <h3>
        {contentMapper(node.content)}
      </h3>
    );
    if (node.nodeType === 'heading-4') return (
      <h4>
        {contentMapper(node.content)}
      </h4>
    );
    if (node.nodeType === 'heading-5') return (
      <h5>
        {contentMapper(node.content)}
      </h5>
    );
    if (node.nodeType === 'heading-6') return (
      <h6>
        {contentMapper(node.content)}
      </h6>
    );
    if (node.nodeType === 'paragraph') return (
      <p>
        {contentMapper(node.content)}
      </p>
    );
    if (node.nodeType === 'blockquote') return (
      <Highlight>
        {CodeMapper({ node: node})}
      </Highlight>
    );
    if (node.nodeType === 'hyperlink') return (
      <a
        href={node.data.uri}
        target="_blank"
        rel="noreferrer"
      >
        {contentMapper(node.content)}
      </a>
    );
    if (node.nodeType === 'text') return (
      <span className={node.marks.map(({ type }) => type).join(' ')}>
        {node.value}
      </span>
    );
    if (node.nodeType === 'document') return (
      <div className="post-content">
        {contentMapper(node.content)}
      </div>
    );
    if (node.nodeType === 'unordered-list') return (
      <ul>
        {contentMapper(node.content)}
      </ul>
    );
    if (node.nodeType === 'list-item') return (
      <li>
        {contentMapper(node.content)}
      </li>
    );
    if (node.nodeType === 'embedded-asset-block') return (
      <img src={images[node.data.target.sys.id]} alt={postENUS?.title || postESCL?.title} />
    );
    return null;
  };

  if (!postENUS || !postESCL) return <div></div>;
  return (
    <div className="post-view-container">
      <div className="container">
      {(language === 'es_CL')
        ? <h1>{postESCL.title}</h1>
        : <h1>{postENUS.title}</h1>}
      {(language === 'es_CL')
        ? <NodeWrapper node={postESCL.content.json} />
        : <NodeWrapper node={postENUS.content.json} />}
      </div>
    </div>
  );
};

export default PostView;